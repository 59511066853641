@use "../mixins/breakpoints";
@use "../config/vars";

.footer {
  border-top: solid 2px vars.$border-color-default;
  padding: vars.$spacing;
  text-align: center;

  .container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: .9rem;
    gap: 1rem;

    @include breakpoints.query("medium") {
      flex-direction: row;
    }
  }
}