@use "../config/vars";
@use "../base/utils";
@use "../mixins/breakpoints";

.section {
  // @extend .debug;
  // height: calc(100vh - #{vars.$spacing * 4});
  min-height: 40rem;
  max-height: 60rem;
  position: relative;
  max-width: 1680px;
  margin: 0 auto;

  &.is-fullwidth {
    max-width: none;
    padding-bottom: 0;
  }

  &.can-grow {
    height: unset;
    max-height: unset;
    padding-bottom: vars.$nav-height;
  }

  header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: vars.$nav-height + vars.$spacing;
    padding-bottom: vars.$nav-height;
    margin-bottom: vars.$spacing;
    text-align: center;
    background: url('../images/backgrounds/stars-header.jpg') no-repeat top center;
    // background: linear-gradient(
    //   to bottom,
    //   darken(vars.$color-accent, 20),
    //   transparent
    // );

    .section-title,
    .section-subtitle {
      margin: 0;
      position: relative;
    }

    .section-subtitle {
      
    }
  }

  & + section {
    border-top: solid 2px vars.$border-color-default;
  }

  @include breakpoints.query("small") {
    max-height: unset;
    height: unset;
    padding-bottom: vars.$spacing;
  }

  @include breakpoints.query("medium") {
    max-height: unset;
    height: unset;
    padding-bottom: vars.$spacing;
  }

  @include breakpoints.query("large") {
    max-height: unset;
    height: unset;
    padding-bottom: vars.$spacing;
  }

  @include breakpoints.query("xlarge") {
    // height: calc(100vh - #{vars.$spacing * 4});
    min-height: 40rem;
    max-height: 60rem;
    // padding-bottom: 0;
  }
}
