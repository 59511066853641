@keyframes spin-cw {
  to {
    transform: rotate(1turn);
  }
}

@keyframes spin-ccw {
  to {
    transform: rotate(-1turn);
  }
}

@keyframes fadeSlideIn {
  from {
    opacity: 0;
    transform: translateY(0);
  }

  to {
    opacity: 1;
    transform: translateY(-200%);
  }
}

@keyframes swing {
  from {
    transform: rotate(-60deg);
  }

  to {
    transform: rotate(60deg);
  }
}

@keyframes fadeSlideInDup {
  from {
    opacity: 0;
    transform: translateY(-100%);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes popIn {
  0% {
    transform: scale(0);
  }

  50% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes wiggle {
  0% {
    transform: rotate(-5deg);
  }

  25% {
    transform: rotate(4deg);
  }

  50% {
    transform: rotate(-3deg);
  }

  75% {
    transform: rotate(2deg);
  }

  100% {
    transform: rotate(0);
  }
}

@keyframes bounce {
  0% {
    transform: rotate(3deg) translateY(0);
  }
  100% {
    transform: rotate(-3deg) translateY(-40px);
  }
}