@use "../config/vars";
@use "../base/utils";

.tiles {
  display: flex;
  @extend .flex-centered;
  flex-direction: row;
  flex-wrap: wrap;
}

.tile {
  display: flex;
  justify-content: center;
  flex: none;
  text-align: center;
  width: vars.$spacing * 5;
  height: vars.$spacing * 5;
  border: vars.$border-default;
  border-width: 2px;
  margin: vars.$spacing * 0.25;
  border-radius: vars.$border-radius;
  overflow: hidden;
  background: vars.$card-color-bg;
  position: relative;
  transition: transform vars.$animation-attr, box-shadow vars.$animation-attr;
  background-size: cover;

  &.has-image {
    
    a {
      display: flex;
      position: absolute;
      justify-content: center;
      align-items: center;
      background: vars.$color-shade;
      inset: 0;
      opacity: 0;
      transition: opacity vars.$animation-attr;

      .tile-title {
        text-shadow: 0 1px 3px vars.$color-shade;
        font-weight: 500;
        color: vars.$color-white;
      }

      &:hover {
        opacity: 1;
        backdrop-filter: blur(1px);
      }
    }

    

  }

  &:hover {
    transform: translateY(-3px);
    box-shadow: vars.$box-shadow-hard;

    a > * {
      color: vars.$color-white;
    }
  }
}

.tile-icon {
  height: vars.$spacing * 3;
  line-height: vars.$spacing * 4;
  font-size: 3rem;
  color: vars.$color-accent;
}

.tile-title {
  font-size: vars.$font-size-default;
  padding: 0 1.5rem;
}
