@use "../config/vars";
@use "../base/utils";
@use "../mixins/breakpoints";

.bubbles {
  text-align: center;
  position: relative;

  .bubbles-title {
    margin-top: vars.$spacing * 2;
  }
}

.bubbles-backdrop {
  position: fixed;
  background: vars.$color-shade;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  z-index: 2;
  display: none;

  &.is-active {
    display: block;
  }
}

.bubble-group {
  margin: vars.$spacing 0;
  position: relative;

  & + .bubble-group {
    margin-top: vars.$spacing * 2;
  }

  &.has-lines {
    &:before,
    &:after {
      content: "";
      border-top: solid 1px vars.$border-color-default;
      width: calc(50vw - 7rem);
      max-width: 20rem;
      height: 0;
      position: absolute;
      top: calc(#{vars.$spacing} * 0.5);
    }
  
    &:before {
      right: 50%;
      margin-right: 5em;
    }
    &:after {
      left: 50%;
      margin-left: 5em;
    }
  }
}

.bubble-items {
  margin-top: vars.$spacing;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: vars.$spacing * 0.5;
}

.bubble-item {
  // @extend .debug;

  // flex: 1;
  width: 200px;
  cursor: pointer;
  padding-top: vars.$nav-height;
  position: relative;
  z-index: 1;

  .bubble-image {
    width: 10rem;
    height: 10rem;
    border-radius: 50%;
    overflow: hidden;
    border: solid 2px vars.$border-color-default;
    margin: vars.$spacing * 0.5 auto;
    position: relative;
    display: block;
    transition: transform vars.$animation-attr, box-shadow vars.$animation-attr;

    img {
      position: relative;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &:after {
      content: "";
      position: absolute;
      width: calc(100% - 4px);
      height: calc(100% - 4px);
      left: 0;
      top: 0;
      border: solid 2px vars.$color-white;
      border-radius: 50%;
    }
  }

  @include breakpoints.query("small") {
    width: calc(50% - #{vars.$spacing});

    .bubble-image {
      width: 9rem;
      height: 9rem;
    }
  }

  .bubble-title,
  .bubble-subtitle {
    display: block;
    max-width: 90%;
    margin: 0 auto;
    transition: transform vars.$animation-attr;
  }

  &.is-active {
    z-index: 2;

    .bubble-image {
      border-width: 4px;
    }

    .bubble-title {
      transform: scale(1.1);
      color: vars.$color-white;
    }

    .bubble-text {
      display: block;
      color: vars.$body-color-bg;
    }

    .bubble-arrow {
      display: block;
    }
  }

  &:hover {
    .bubble-image {
      transform: translateY(-3px);
      box-shadow: vars.$box-shadow-hard;
    }

    .bubble-title {
      transform: scale(1.1);
      color: vars.$color-white;
    }

    .bubble-subtitle {
      transform: translateY(3px);
    }
  }
}

.bubble-arrow {
  position: absolute;
  left: 50%;
  margin-left: -10px;
  margin-top: 10px;
  z-index: vars.$z-index-max;
  display: none;

  &:before {
    content: "";
    position: absolute;
    border: solid 10px transparent;
    border-bottom-color: vars.$color-white;
    border-top-width: 0;
  }
}

.bubble-text {
  position: absolute;
  text-align: left;
  background: vars.$color-white;
  padding: vars.$spacing * 0.5;
  width: 24rem;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: vars.$box-shadow;
  border-radius: vars.$border-radius;
  top: calc(100% + #{vars.$spacing * 0.5});
  display: none;
  z-index: vars.$z-index-max - 3;

  @include breakpoints.query("small") {
    width: calc(100vw - #{vars.$spacing * 2.5});
  }
}

.bubble-text-content-wrapper {
  max-height: 100%;
  width: 100%;
  overflow: auto;
}
