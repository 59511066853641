@use "../config/vars";
@use "../base/utils";

* {
  box-sizing: border-box;
}

body {
  background: vars.$body-color-bg;
  color: vars.$body-color-text;
  margin: 0;
  font-family: sans-serif;
  min-width: 20rem;
}



.container {
  min-width: 20rem;
  max-width: 60rem;
  height: inherit;
  max-height: inherit;
  width: 100%;
  margin: 0 auto;
}
