@use "./config/vars";

// GLOBALS
body {
  background: var(--c-primary-darkest);
}

.hero {
  background-image: var(--i-hero);
}

.section header {
  background-image: radial-gradient(ellipse at top, var(--c-primary) -40%, transparent 60%),
}

.section+section,
.footer {
  border-top: solid 1px var(--c-primary-lighter);
}

.list {
  border-color: var(--c-primary-lighter);

  >li {
    background-color: var(--c-primary-darker);

    &:nth-child(even) {
      background-color: var(--c-primary);
    }

    a {
      color: white;

      &:hover {
        color: white;
        text-decoration: underline;
      }
    }
  }
}

.tile {
  border-color: var(--c-primary);
}

.bubble-item .bubble-image {
  border-color: var(--c-primary);
}

h1,
h2,
h3,
h4 {
  font-family: var(--f-primary);
  color: var(--c-primary-lighter);
}

sub {
  color: var(--c-primary);
  font-family: var(--f-primary);
}

a:not(.button) {
  color: var(--c-primary);

  &:hover {
    color: var(--c-primary-lighter);
  }
}

// COMPONENTS
.nav {
  body.nav-is-sticky & {
    background: var(--c-primary);
    border-color: var(--c-primary-darker);

    ul>li {
      border-color: var(--c-primary-darker);

      .nav-item {
        background: var(--c-primary);
        
        &.is-active {
          background: var(--c-accent);
        }
      }
    }
  }
  
  ul>li>.nav-item {
    background: var(--c-primary);
    font-family: var(--f-primary);
    
    &:hover {
      background: var(--c-primary-darker);
    }
  }

  .nav-hamburger {
    background: var(--c-primary-darker);
  }
}

.button {
  background: var(--c-primary);
  font-family: var(--f-primary);

  &:hover {
    background: var(--c-primary-darker);
  }
}

// LOGO
#lottieLogo {
  &:before {
    content: var(--logo-subtitle);
    position: absolute;
    bottom: 2.35rem;
    left: 0;
    width: 100%;
    text-align: center;
    font-family: vars.$font-family-primary;
    font-size: 2rem;
    letter-spacing: .6rem;
    text-shadow: 0 1px 4px rgba(black, .8), 0 2px 10px rgba(black, .8);
    transition: opacity .7s ease-in, transform 1s ease-in;
  }

  &.is-initialized:before {
    opacity: 0;
    transform: translateY(-30px);
  }

  svg>g>g:nth-of-type(2) path {
    display: none;
  }

  svg>g>g:nth-of-type(4) path {
    stroke: var(--c-primary);
  }
}

.columns .column .column-text {
  background: rgba(white, .1);
}