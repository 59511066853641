// Base bundle
@use 'bundle';

:root {
  --c-primary: #0ac;
  --c-primary-lighter: #aef;
  --c-primary-darker: #08a;
  --c-primary-darkest: #012;
  --c-primary-alt: #035;

  --c-accent: #08a;

  --f-primary: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  --logo-subtitle: 'media';

  --i-hero: url('../images/backgrounds/bokeh.jpg');
}

@import 'base/patch'