// Dimensions
$spacing: 2rem;
$nav-height: 3.25rem;

// Responsive
$max-width-mobile: 48rem;
$breakpoints: (
  "small": (
    max-width: 540px,
  ),
  "medium": (
    min-width: 541px,
  ),
  "large": (
    min-width: 768px,
  ),
  "xlarge": (
    min-width: 993px,
  ),
) !default;

// Typography
$font-family-primary: "Quicksand";
$font-family-secondary: "Roboto";
$font-size-large: 2.4rem;
$font-size-medium: 1.2rem;
$font-size-default: 1.1rem;
$font-size-small: 0.9rem;

// Color theme
$color-primary: #8DAE4F;
$color-secondary: rgba($color-primary, .7);
$color-accent: #8DAE4F;
$color-black: #222;
$color-navy: #072235;
$color-white: white;
$color-gray: #aaa;
$color-milk: rgba(255 255 255 / 0.8);
$color-shade: rgba(0 0 0 / 0.4);

// Color mapping
$body-color-bg: $color-navy;
$body-color-text: $color-white;

$card-color-bg: darken($color-navy, 5);

$link-color-bg: $color-primary;
$link-color-text: $color-white;
$link-color-bg-hover: darken($color-primary, 15);
$link-color-text-hover: $color-white;
$border-color-default: lighten($color-navy, 15);

// Units
$border-radius-round: 99em; //.7rem;
$border-radius: 1rem;
$z-index-max: 9999;

// Decorations
$box-shadow: 0 5px 15px rgba(0 0 0 / 0.4);
$box-shadow-hard: 0 3px 0 $color-shade;
$blur: blur(5px);
$border-default: solid 1px $border-color-default;

// Animation
$animation-duration-default: 0.2s;
$animation-duration-slow: 1s;
$animation-ease-default: ease;
$animation-attr: $animation-duration-default $animation-ease-default;
$animation-attr-slow: $animation-duration-slow $animation-ease-default;
