@use "../config/vars";
@use "sass:math";

.list {
  list-style: none;
  margin: 0;
  padding: 0;
  background: vars.$card-color-bg;
  border-radius: vars.$border-radius;
  border: solid 2px vars.$border-color-default;
  box-shadow: vars.$box-shadow;
  overflow: hidden;
  margin-bottom: vars.$spacing;

  > li {
    margin: 0;
    padding: math.div(vars.$spacing, 2);

    &:nth-child(2n) {
      background: lighten(vars.$card-color-bg, 3);
    }
  }
}