@use "../config/vars";
@use "../base/utils";
@use "../base/animation";
@use "../mixins/breakpoints";

.nav-wrapper {
  position: relative;
  height: 0;
  z-index: vars.$z-index-max;
}

.nav {
  display: flex;
  justify-content: center;
  animation: fadeSlideIn vars.$animation-attr;
  transition: transform vars.$animation-attr;
  transform: translateY(-200%);

  &.is-open {
    top: 0;
    transform: translateY(0) !important;
    z-index: vars.$z-index-max + 1;
  }

  .nav-is-sticky & {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    transform: none;
    z-index: vars.$z-index-max - 2;
    background: vars.$color-accent;
    border-bottom: solid 1px vars.$border-color-default;
    animation: fadeSlideInDup vars.$animation-attr;

    > ul {
      > li {
        > .nav-item {
          border-radius: 0 !important;
          border-bottom: 0;

          &.is-active {
            background: vars.$color-accent;
            color: vars.$color-white;
          }
        }
      }
    }
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;

    > li {
      .nav-item {
        position: relative;
        display: inline-block;
        background: vars.$link-color-bg;
        padding: 1rem 0;
        border-radius: 0;
        min-width: 8rem;
        text-align: center;
        color: vars.$link-color-text;
        // border-bottom: solid 1px vars.$border-color-default;
        transition: background vars.$animation-attr,
          transform vars.$animation-attr, box-shadow vars.$animation-attr;

        &:hover {
          background: vars.$link-color-bg-hover;
          transform: translateY(-3px);
          z-index: vars.$z-index-max;
          box-shadow: vars.$box-shadow-hard;
        }
      }
      &:not(:last-child) {
        border-bottom: solid vars.$border-color-default 1px;

        @include breakpoints.query("medium") {
          border-bottom: none;
          border-right: solid vars.$border-color-default 1px;
        }
      }

      &:first-child .nav-item {
        border-top-left-radius: vars.$border-radius-round;
        border-bottom-left-radius: vars.$border-radius-round;
      }

      &:last-child .nav-item {
        border-top-right-radius: vars.$border-radius-round;
        border-bottom-right-radius: vars.$border-radius-round;
        margin: 0;
      }
    }
  }

  // Mobile
  @include breakpoints.query("small") {
    position: fixed;
    top: 0;
    transform: translateY(-100%) !important;

    > ul {
      flex-direction: column;
    }

    > ul > li > .nav-item {
      border-radius: 0 !important;
      width: 100vw;
    }
  }

  @include breakpoints.query("medium") {
    > ul > li > .nav-item {
      min-width: unset;
      padding-left: vars.$spacing * 0.5;
      padding-right: vars.$spacing * 0.5;
    }
  }

  @include breakpoints.query("large") {
    > ul > li > .nav-item {
      min-width: 8rem;
    }
  }
}

.nav-hamburger {
  width: 100vw;
  height: 3rem;
  background: darken(vars.$color-accent, 15);
  position: absolute;
  bottom: -3rem;
  left: 0;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: vars.$box-shadow;
  color: vars.$color-white;
}
