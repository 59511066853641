@use "../config/vars";
@use "../base/utils";
@use "sass:math";

.button {
  display: inline-block;
  padding: math.div(vars.$spacing, 2) vars.$spacing;
  background: vars.$color-accent;
  border-radius: 99rem;
  transition: transform vars.$animation-duration-default vars.$animation-ease-default, background vars.$animation-duration-default vars.$animation-ease-default;
  color: vars.$link-color-text;

  &:hover {
    transform: translateY(-4px);
    background: darken(vars.$color-accent, 10);
  }
}