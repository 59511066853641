@use "../config/vars";
@use "../base/utils";
@use "../base/animation";
@use "../mixins/breakpoints";

.graphic {
  position: absolute;
  min-width: 3rem;
  min-height: 3rem;
  max-width: 100%;

  &.is-flipped {
    transform: scaleX(-100%);
  }

  img,
  svg {
    width: 100%;
    height: 100%;
  }

  &.is-on-top {
    z-index: vars.$z-index-max;
  }

  &.is-fullwidth {
    @include breakpoints.query("small") {
      width: 300% !important;
      left: 50% !important;
      transform: translate(-50%, 15%) !important;
    }
  }
}

#lottieLogo {
  transform: scale(0);
  animation: popIn vars.$animation-attr-slow forwards;
  animation-delay: 0.2s;
  cursor: pointer;
  transition: transform vars.$animation-attr;

  &:not(.is-playing):hover svg {
    animation: wiggle vars.$animation-attr forwards;
  }

  svg {
    filter: drop-shadow(0px 2px 3px rgb(0 0 0 / 0.8));
  }

  svg path {
    stroke: vars.$color-white;
    fill: vars.$color-white;
  }

  svg > g > g:nth-of-type(4) path {
    stroke: vars.$color-accent;
  }
}
