@use "../config/vars";
@use "../mixins/breakpoints";

.has-text-centered {
  text-align: center;
}

.flex-centered {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.debug {
  outline: solid 1px red;
  background: rgba(red, 0.1);
}

.is-absolute {
  position: absolute;
}

.is-bottom-left {
  @extend .is-absolute;
  left: 0;
  bottom: 0;
}

.is-bottom-right {
  @extend .is-absolute;
  right: 0;
  bottom: 0;
}

.is-top-right {
  @extend .is-absolute;
  right: 0;
  top: 0;
}

@include breakpoints.query("medium") {
  .is-visible-mobile {
    display: none !important;
  }
}
