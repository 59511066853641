@use "../config/vars";
@use "../base/utils";
@use "../mixins/breakpoints";
@use "sass:math";

.columns {
  display: flex;
  justify-content: center;
  gap: vars.$spacing;

  .column {
    display: flex;
    flex-direction: column;
    flex: 1;
    position: relative;

    .column-title {
      margin-bottom: math.div(vars.$spacing, 2);
    }

    .column-text {
      padding: vars.$spacing;
      background: rgba(vars.$card-color-bg, .7);
      // border: solid 1px vars.$border-color-default;
      // min-height: calc(100% - 3rem);
      border-radius: vars.$border-radius;
      backdrop-filter: blur(10px);
      margin: 0;
    }

    .button {
      align-self: center;
      flex: none;
      position: relative;
      margin-top: vars.$spacing;
      
    }
  }

  @include breakpoints.query("small") {
    gap: 0;
    flex-direction: column;
    margin-left: vars.$spacing * 0.25;
    margin-right: vars.$spacing * 0.25;

    .column-title {
      padding-left: math.div(vars.$spacing, 2);
    }
  }

  @include breakpoints.query("medium") {
    gap: 0;
    flex-direction: column;
    margin-left: vars.$spacing * 0.5;
    margin-right: vars.$spacing * 0.5;



    .column-title {
      padding-left: vars.$spacing;
    }
  }

  @include breakpoints.query("large") {
    gap: vars.$spacing;
    flex-direction: row;

    .column {
      max-width: 600px;
    }

    .column-title {
      padding-left: 0;
    }
  }
  @include breakpoints.query("xlarge") {
    margin-left: 0;
    margin-right: 0;

    .column-title {
      padding-left: 0;
    }
  }
}
