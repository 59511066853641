@use "../config/vars";
@use "../base/utils";
@use "../base/animation";
@use "../mixins/breakpoints";

.atoms {
  position: relative;
  margin: 0 auto;
  max-width: 100vw;
  overflow: hidden;

  &:before {
    content: "";
    position: absolute;
    inset: 0;
    background: url("../images/atoms-circle.svg") center center no-repeat;
    animation: spin-cw 60s linear infinite;
    animation-delay: -5s;
  }
}

.atom-wrapper {
  position: absolute;
  width: 35rem;
  transform-origin: 7.5rem 7.5rem;
  display: flex;
  justify-content: flex-end;
  left: calc(50% - 7.5rem);
  top: calc(50% - 7.5rem);
  animation: spin-cw 60s linear infinite;

  @for $i from 1 through 6 {
    &:nth-child(#{$i}) {
      animation-delay: -#{$i * 10}s;

      .atom {
        animation: spin-ccw 60s linear infinite;
        animation-delay: -#{$i * 10}s;
      }
    }
  }

  // Center atom
  &:nth-child(7) {
    width: unset;
    justify-content: flex-start;
    animation: none;
    transform: translateY(10px);
  }
}

.atom {
  @extend .flex-centered;

  width: 15rem;
  height: 15rem;
  border: solid 2px vars.$border-color-default;
  background: rgba(vars.$color-primary, .85);
  border-radius: 100%;
  position: relative;
  cursor: pointer;
  transition: background vars.$animation-attr, box-shadow vars.$animation-attr;
  box-shadow: 0 0 25px vars.$color-shade;

  &.is-static {
    pointer-events: none;
    background: rgba(vars.$border-color-default, .85);
    backdrop-filter: blur(2px);
    
    .atom-title {
      font-weight: 500;
    }
  }

  &.is-active,
  &:hover {
    background: vars.$color-white;
    box-shadow: vars.$box-shadow-hard;

    .atom-title {
      color: vars.$color-primary;
    }

    .atom-image {
      filter: invert(1);
    }
  }
}

.atom-text {
  display: none;
}

.atom-image {
  height: 2rem;
  width: 1.5rem;
  margin-top: 0.5rem;
  margin-bottom: -1.5rem;
  object-fit: contain;
  opacity: .5;

  &.is-wide {
    width: 3rem;
  }
}

.atom-title {
  color: vars.$color-white;
  text-align: center;
  font-size: 1.5rem;
}

@include breakpoints.query("small") {
  .atoms {
    width: 100vw;
    height: 26rem;
  }

  .atom-wrapper {
    width: 16rem;
    transform-origin: 3.5rem 3.5rem;
    left: calc(50% - 3.5rem);
    top: calc(50% - 3.5em);
  }

  .atom {
    height: 7rem;
    width: 7rem;
  }

  .atom-title {
    font-size: vars.$font-size-medium;
  }
}

@include breakpoints.query("medium") {
  .atoms {
    width: 100vw;
    height: 40rem;
  }

  .atom-wrapper {
    width: 24rem;
    transform-origin: 5rem 5rem;
    left: calc(50% - 5rem);
    top: calc(50% - 5em);
  }

  .atom {
    height: 10rem;
    width: 10rem;
  }
}

@include breakpoints.query("xlarge") {
  .atoms {
    width: 55rem;
    height: 58rem;
  }

  .atom-wrapper {
    width: 34rem;
    transform-origin: 7rem 7rem;
    left: calc(50% - 7rem);
    top: calc(50% - 7em);
  }

  .atom {
    height: 14rem;
    width: 14rem;
    
  }
}
