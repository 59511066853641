@use "../config/vars";

.hero {
  background: url('../images/backgrounds/stars-hq.jpg');
  background-size: cover;
  background-position: center;
  height: 100vh;
  max-height: 800px;
  min-height: inherit;
  text-align: center;

  .container {
    height: inherit;
    max-height: inherit;
    min-height: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
    // position: relative;

  }

  // &:after {
  //   content: '';
  //   position: absolute;
  //   bottom: 0;
  //   left: 0;
  //   width: 100%;
  //   height: 100px;
  //   background: linear-gradient(to top, vars.$body-color-bg, rgba(vars.$body-color-bg, 0));
  // }
}
