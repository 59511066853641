@use "../config/vars";

@import url("https://fonts.googleapis.com/css2?family=#{vars.$font-family-primary}&family=#{vars.$font-family-secondary}&display=swap");

a {
  color: vars.$link-color-text;
  text-decoration: none;
}

body {
  font-family: vars.$font-family-secondary;
  font-size: 1.1rem;
}

h1,
h2,
h3,
h4,
h5 {
  color: vars.$color-accent;
  font-family: vars.$font-family-primary;
  margin: 0;
}

h1 {
  font-size: 4rem;
}

h2 {
  font-size: 2.4rem;
  font-weight: 500;
}

h3 {
  font-size: 1.8rem;
  font-weight: 400;
}

h4 {
  font-size: 1.4rem;
  font-weight: 500;
}

p {
  line-height: 1.4;
}

sub {
  color: vars.$color-secondary;
  font-size: 1.25rem;
}

h1 {
  font-size: vars.$font-size-large;
  line-height: vars.$font-size-large;
  font-weight: 300;
}

a {
  color: vars.$color-secondary;
  transition: color vars.$animation-duration-default;

  &:hover {
    color: vars.$color-white;
  }
}