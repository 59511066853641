@use "../config/vars";
@use "../base/utils";

.modal-wrapper {
  &.is-active {
    .modal {
      transform: translateX(-50%) translateY(0);
    }
    .modal-backdrop {
      opacity: 1;
      pointer-events: all;
    }
  }
}

.modal {
  border: solid 1px vars.$border-color-default;
  border-bottom: none;
  background: vars.$card-color-bg;
  backdrop-filter: vars.$blur;
  position: fixed;
  bottom: 0;
  left: 50%;
  width: calc(100vw - #{vars.$spacing * 8});
  min-width: 20rem;
  max-width: 40rem;
  height: 40rem;
  max-height: calc(100vh - #{vars.$spacing * 4});
  display: flex;
  flex-direction: column;
  box-shadow: vars.$box-shadow;
  border-radius: vars.$border-radius;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  transform: translateX(-50%) translateY(100%);
  transition: transform vars.$animation-attr;
  z-index: vars.$z-index-max - 1;
  overflow: hidden;

  // Children
  > * {
    padding: vars.$spacing * 0.75;
  }
}

.modal-header {
  display: flex;
  flex: none;
  border-bottom: vars.$border-default;

  .button {
    @extend .flex-centered;
    height: vars.$spacing * 2;
    margin: vars.$spacing * -0.5 0;
    padding: 0 vars.$spacing * 0.5;
    font-size: vars.$font-size-default;
    cursor: pointer;
    user-select: none;
    background: none;
    transition: color vars.$animation-attr;

    &.modal-prev {
      margin-left: vars.$spacing * -0.5;
    }

    &.modal-close {
      font-size: vars.$font-size-large;
      margin-right: vars.$spacing * -0.5;
    }

    &:hover {
      transform: none;
      color: vars.$color-accent;
    }
  }

  .modal-header-nav {
    flex: auto;
    display: flex;
  }

  .modal-close {
    flex: none;
  }
}

.modal-content {
  flex: auto;
}

.modal-title {
  text-transform: capitalize;
}

.modal-backdrop {
  position: fixed;
  inset: 0;
  background: vars.$color-shade;
  opacity: 0;
  transition: opacity vars.$animation-attr;
  pointer-events: none;
  z-index: vars.$z-index-max - 2;
}

.modal-image {
  opacity: 0.1;
  position: absolute;
  width: 80%;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  pointer-events: none;
}
